import React, { useState, useEffect } from "react";
import classNames from "classnames";

function Languages() {
  const [selectedKey, setSelectedKey] = useState("tamil");
  const [selectedMas, setSelectedMas] = useState(new Set());
  const [selectedKas, setSelectedKas] = useState(new Set());

  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  function onMaClick(id: number) {
    const s = new Set(selectedMas);
    if (!selectedMas.has(id)) {
      setSelectedMas(s.add(id));
    } else {
      s.delete(id);
      setSelectedMas(s);
    }
  }

  function onKaClick(id: number) {
    const s = new Set(selectedKas);
    if (!selectedKas.has(id)) {
      setSelectedKas(s.add(id));
    } else {
      s.delete(id);
      setSelectedKas(s);
    }
  }

  /* <LanguageSelect
       name=""
       subtitle="Just show me what the words in the book mean"
       onClick={() => setSelectedKey("diy")}
       isLangSelected={selectedKey === "diy"}
       />*/

  return (
    <div>
      <h2>Everyone Learns Our Languages</h2>
      Welcome to our Indian-American tutorial on how to teach yourself the
      script in a new part of India. First, choose a language:
      <div className="lang-select-container">
        <LanguageSelect
          name="Tamil"
          subtitle=""
          onClick={() => setSelectedKey("tamil")}
          isLangSelected={selectedKey === "tamil"}
        />
        <LanguageSelect
          name="Hindi"
          subtitle=""
          onClick={() => setSelectedKey("hindi")}
          isLangSelected={selectedKey === "hindi"}
        />
      </div>
      {selectedKey === "hindi" && (
        <div>
          <h3> Lesson 1: Ka and the transliterated "a"</h3>
          <div className="lang-blog">
            First, we're going to learn about one Hindi letter. The letter is
            transliterated as "ka", and pronounced as the "cu" in{" "}
            <span className="a-as-in">cu</span>p.
          </div>
          <div className="lang-step">
            <CharacterBox contents={<div>क</div>} subtitle="ka" big={true} />
          </div>
          <div className="lang-step">
            <p>
              Party trick: Whenever you see the letter "a" in transliterated
              Indian languages, it's almost always going to be pronounced like
              the "u" in c<span className="a-as-in">u</span>p, or like the "a"
              in yog
              <span className="a-as-in-yoga">a</span>.
            </p>

            <p>
              This single trick is enough to make sense of the pronunciation of
              many Indian names. K<span className="a-as-in">a</span>m
              <span className="a-as-in">a</span>l
              <span className="a-as-in-yoga">a</span> Harris's name has two
              cup-a&apos;s and one yoga-a. (The a in Harris is an American a,
              not covered here.)
            </p>
          </div>
          <h3> Lesson 2: Find क</h3>
          <div className="lang-step">
            {" "}
            Your next task is to find the letter क and some of its variants in a
            block of text. Click on the letters that seem similar to क in the
            paragraph below.
          </div>
          <CharacterBox
            big={false}
            loose={true}
            contents={
              <div>
                जितनी जल्दी हम
                <MaIshLetter
                  onClick={() => onKaClick(1)}
                  isSelected={selectedKas.has(1)}
                  letter=" कि"
                />
                सी
                <MaIshLetter
                  onClick={() => onKaClick(2)}
                  isSelected={selectedKas.has(2)}
                  letter=" को "
                />
                धन्यवाद
                <MaIshLetter
                  onClick={() => onKaClick(3)}
                  isSelected={selectedKas.has(3)}
                  letter=" क"
                />
                ह
                <MaIshLetter
                  onClick={() => onKaClick(4)}
                  isSelected={selectedKas.has(4)}
                  letter=" क"
                />
                र अपना आभार जताते हैं, उतनी ही जल्दी हम उन
                <MaIshLetter
                  onClick={() => onKaClick(5)}
                  isSelected={selectedKas.has(5)}
                  letter=" के "
                />
                <MaIshLetter
                  onClick={() => onKaClick(6)}
                  isSelected={selectedKas.has(6)}
                  letter=" कि"
                />
                ये हुए अनुग्रह
                <MaIshLetter
                  onClick={() => onKaClick(7)}
                  isSelected={selectedKas.has(7)}
                  letter=" को "
                />
                भूल जाते हैं। इस
                <MaIshLetter
                  onClick={() => onKaClick(8)}
                  isSelected={selectedKas.has(8)}
                  letter="के "
                />
                बजाय अगर हम
                <MaIshLetter
                  onClick={() => onKaClick(9)}
                  isSelected={selectedKas.has(9)}
                  letter=" कृ"
                />
                तज्ञ हों, अर्थात् हमें
                <MaIshLetter
                  onClick={() => onKaClick(10)}
                  isSelected={selectedKas.has(10)}
                  letter=" कृ"
                />
                तज्ञता
                <MaIshLetter
                  onClick={() => onKaClick(11)}
                  isSelected={selectedKas.has(11)}
                  letter=" की "
                />
                भावना
                <MaIshLetter
                  onClick={() => onKaClick(12)}
                  isSelected={selectedKas.has(12)}
                  letter=" का "
                />
                अहसास हो, तो यह भावना धन्यवाद
                <MaIshLetter
                  onClick={() => onKaClick(13)}
                  isSelected={selectedKas.has(13)}
                  letter=" की "
                />
                अपेक्षा ज्यादा टि
                <MaIshLetter
                  onClick={() => onKaClick(14)}
                  isSelected={selectedKas.has(14)}
                  letter="का"
                />
                ऊ और स्थायी है। जब त
                <MaIshLetter
                  onClick={() => onKaClick(15)}
                  isSelected={selectedKas.has(15)}
                  letter="क "
                />
                हम{" "}
                <MaIshLetter
                  onClick={() => onKaClick(16)}
                  isSelected={selectedKas.has(16)}
                  letter=" कृ"
                />
                तज्ञ रहेंगे तब त
                <MaIshLetter
                  onClick={() => onKaClick(17)}
                  isSelected={selectedKas.has(17)}
                  letter="क "
                />
                हमें उस अनुग्रह
                <MaIshLetter
                  onClick={() => onKaClick(18)}
                  isSelected={selectedKas.has(18)}
                  letter=" का "
                />
                स्मरण रहेगा।
              </div>
            }
            subtitle="a paragraph of text in Hindi"
          />
          <div className="lang-step">
            {" "}
            {selectedKas.size > 3 &&
              `You found ${selectedKas.size} of out 18!`}{" "}
            You might have some questions now. Like,
            <ul>
              <li> "What's with the things sticking out of the कs?" </li>
              <li>
                {" "}
                "How come sometimes I click on a क and something next to it gets
                highlighted as well?"{" "}
              </li>
            </ul>
            We address these questions one at a time.
          </div>
          <h3> Lesson 3: Vowels </h3>
          <div className="lang-step">
            Most Indian scripts (including Hindi) use a syllabic script; that
            is, each letter represents a consonant base form with a vowel
            modifier. Below, क and प are the consonant base forms and ी is the
            vowel modifier.
          </div>
          <CharacterBox contents={<div>क</div>} subtitle="ka" big={true} />
          <CharacterBox contents={<div>की</div>} subtitle="kih" big={true} />
          <br />
          <CharacterBox contents={<div>प</div>} subtitle="pa" big={true} />
          <CharacterBox contents={<div>पी</div>} subtitle="pih" big={true} />
          <div className="lang-step">
            Sometimes the vowel modifier is connected to the consonant, and
            sometimes it appears right after the consonant. Example: The vowel
            modifier that adds the -ah sound comes after its consonant:
          </div>
          <div className="lang-step">
            <CharacterBox contents={<div>क</div>} subtitle="ka" big={true} />
            <CharacterBox contents={<div>का</div>} subtitle="kah" big={true} />
          </div>
          <div className="lang-step">
            Vowels in Hindi are very regular; once you learn how they modify one
            consonant, you can follow the pattern for every other consonant.
          </div>
          <h3> Lesson 4: Apply What You've Learned</h3>
          <div className="lang-step">
            Take a look at this Metro sign in Delhi. The station name is written
            in both English and in Hindi. How many of the letters can you figure
            out?{" "}
          </div>
          <div className="center">
            <img
              className="languages-apply-image"
              src="kalkaji.jpeg"
              alt="Metro sign in English and Hindi"
            ></img>
          </div>
          <div className="lang-step">
            Here is the same text, so you can see it without the angle:
          </div>
          <div className="center">
            <CharacterBox
              contents={<div>कालकाजी मंदिर</div>}
              subtitle="Kalkaji Mandir"
              big={true}
            />
          </div>
          <div className="knee-done">
            Congrats! You have finished the lesson. Feel free to return to the
            play.
          </div>
        </div>
      )}
      {selectedKey === "tamil" && (
        <div className="lang-blog">
          <h3> Lesson 1: Ma</h3>
          <div className="lang-step">
            {" "}
            First, we're going to learn about one Tamil letter. When you see
            this letter, you should pronounce it "ma".
          </div>
          <div className="lang-step">
            <CharacterBox contents={<div>ம</div>} subtitle="ma" big={true} />
          </div>
          <div className="lang-step">
            The vowel sound "a" that we are using here, the default vowel in
            Tamil, is pronounced the way an American might pronounce "uh". Think{" "}
            <span className="a-as-in">A</span> as in{" "}
            <span className="a-as-in">A</span>
            mazing. (There are two "a" sounds in Tamil&mdash;this one, and a
            longer "ah" sound; <span className="a-as-in-yoga">a</span> as in yog
            <span className="a-as-in-yoga">a</span>.)
          </div>
          <h3> Lesson 2: Find ம</h3>
          <div className="lang-step">
            {" "}
            Your next task is to find the letter ம and some of its variants in a
            block of text. Click on the letters that seem similar to ம in the
            paragraph below.
          </div>
          <CharacterBox
            big={false}
            contents={
              <div>
                <MaLetter
                  onClick={() => onMaClick(1)}
                  isSelected={selectedMas.has(1)}
                />
                தங்கள் கடவுளிட
                <MaIshLetter
                  onClick={() => onMaClick(2)}
                  isSelected={selectedMas.has(2)}
                  letter="ம் "
                />
                சரணடையச் சொல்வதன் காரண
                <MaIshLetter
                  onClick={() => onMaClick(3)}
                  isSelected={selectedMas.has(3)}
                  letter="மே "
                />
                இந்த ச
                <MaLetter
                  onClick={() => onMaClick(4)}
                  isSelected={selectedMas.has(4)}
                />
                த்துவத்துக்கான பெருவி
                <NotMaLetter
                  onClick={() => onMaClick(5)}
                  isSelected={selectedMas.has(5)}
                  letter="ழை"
                />
                வுதான். அப்படிப்பட்ட பேரறிவு, பெருங்கருணை, பேருரு, பேராளு
                <MaIshLetter
                  onClick={() => onMaClick(6)}
                  isSelected={selectedMas.has(6)}
                  letter="மை"
                />
                யிட
                <MaIshLetter
                  onClick={() => onMaClick(7)}
                  isSelected={selectedMas.has(7)}
                  letter="ம் "
                />
                தான் அனைவரையு
                <MaIshLetter
                  onClick={() => onMaClick(38)}
                  isSelected={selectedMas.has(38)}
                  letter="ம் "
                />
                ஏற்று, அனைவருடனு
                <MaIshLetter
                  onClick={() => onMaClick(8)}
                  isSelected={selectedMas.has(8)}
                  letter="ம் "
                />
                ஒரே ச
                <MaLetter
                  onClick={() => onMaClick(9)}
                  isSelected={selectedMas.has(9)}
                />
                ய
                <MaIshLetter
                  onClick={() => onMaClick(10)}
                  isSelected={selectedMas.has(10)}
                  letter="ம் "
                />
                பகிர்ந்து, அனைவருக்கு
                <MaIshLetter
                  onClick={() => onMaClick(11)}
                  isSelected={selectedMas.has(11)}
                  letter="ம் "
                />
                குரல் கொடுக்க வாய்ப்பு கொடுத்து, அனைவரு
                <MaIshLetter
                  onClick={() => onMaClick(12)}
                  isSelected={selectedMas.has(12)}
                  letter="ம் "
                />
                உண்டு, அனைவரையு
                <MaIshLetter
                  onClick={() => onMaClick(13)}
                  isSelected={selectedMas.has(13)}
                  letter="ம் "
                />
                உண்டு, அனைவரு
                <MaIshLetter
                  onClick={() => onMaClick(14)}
                  isSelected={selectedMas.has(14)}
                  letter="ம் "
                />
                ஜீரணித்து, அனைவரையு
                <MaIshLetter
                  onClick={() => onMaClick(15)}
                  isSelected={selectedMas.has(15)}
                  letter="ம் "
                />
                ஜீரணித்து ஒன்றாக்கு
                <MaIshLetter
                  onClick={() => onMaClick(16)}
                  isSelected={selectedMas.has(16)}
                  letter="ம் "
                />
                வாய்ப்பு இருக்கிறது. இத்தகைய பேராளு
                <MaIshLetter
                  onClick={() => onMaClick(17)}
                  isSelected={selectedMas.has(17)}
                  letter="மை"
                />
                க்கு கால, இட நெருக்கடிகள் ஏது
                <MaIshLetter
                  onClick={() => onMaClick(18)}
                  isSelected={selectedMas.has(18)}
                  letter="ம் "
                />
                இல்லை.
                <MaIshLetter
                  onClick={() => onMaClick(19)}
                  isSelected={selectedMas.has(19)}
                  letter=" மு"
                />
                ப்பரி
                <MaIshLetter
                  onClick={() => onMaClick(20)}
                  isSelected={selectedMas.has(20)}
                  letter="மா"
                />
                ண
                <MaIshLetter
                  onClick={() => onMaClick(21)}
                  isSelected={selectedMas.has(21)}
                  letter="ம் "
                />
                கூட இல்லை, எப்பரி
                <MaIshLetter
                  onClick={() => onMaClick(22)}
                  isSelected={selectedMas.has(22)}
                  letter="மா"
                />
                ண
                <MaIshLetter
                  onClick={() => onMaClick(23)}
                  isSelected={selectedMas.has(23)}
                  letter="மு"
                />
                <MaIshLetter
                  onClick={() => onMaClick(24)}
                  isSelected={selectedMas.has(24)}
                  letter="ம் "
                />
                அதை அடைக்க
                <MaIshLetter
                  onClick={() => onMaClick(25)}
                  isSelected={selectedMas.has(25)}
                  letter=" மு"
                />
                டியாத பேராளு
                <MaIshLetter
                  onClick={() => onMaClick(26)}
                  isSelected={selectedMas.has(26)}
                  letter="மை "
                />
                என்று கடவுளை உருவகித்த காரண
                <MaIshLetter
                  onClick={() => onMaClick(27)}
                  isSelected={selectedMas.has(27)}
                  letter="மு"
                />
                <MaIshLetter
                  onClick={() => onMaClick(28)}
                  isSelected={selectedMas.has(28)}
                  letter="ம் "
                />
                அதுவே ஆக இருக்க
                <MaIshLetter
                  onClick={() => onMaClick(29)}
                  isSelected={selectedMas.has(29)}
                  letter=" மு"
                />
                டியு
                <MaIshLetter
                  onClick={() => onMaClick(30)}
                  isSelected={selectedMas.has(30)}
                  letter="ம்"
                />
                .
              </div>
            }
            subtitle="a paragraph of text in Tamil"
          />
          <div className="lang-step">
            {" "}
            Great job! How many did you find? {selectedMas.size}? You might have
            some questions now. Like,
            <ul>
              <li> "What's with all the மs that had dots on top of them?" </li>
              <li>
                {" "}
                "How come sometimes I clicked on a ம and another thing next to
                it got highlighted, too?"{" "}
              </li>
              <li> "How come one of them turned red instead of green?" </li>
            </ul>
            Let's address each of these questions one at a time.
          </div>
          <h3> Lesson 3: What do Dots do? </h3>
          <div className="lang-step">
            When you add a dot on top of a letter, you silence the vowel. The
            dot is sort of like an anti-vowel. Let's take a look at ம again:
          </div>
          <div className="lang-step">
            <CharacterBox contents={<div>ம</div>} subtitle="ma" big={true} />
            <CharacterBox contents={<div>ம்</div>} subtitle="m-" big={true} />
          </div>
          <div className="lang-step">
            Here's another example with a new letter:
          </div>
          <CharacterBox contents={<div>ப</div>} subtitle="pa" big={true} />
          <CharacterBox contents={<div>ப்</div>} subtitle="p-" big={true} />
          <h3> Lesson 4: What about other Vowels? </h3>
          <div className="lang-step">
            Tamil (and all the other Indian languages that I know of) uses a
            syllabic script; that is, each letter represents a consonant base
            form with a vowel modifier.
          </div>
          <CharacterBox contents={<div>ம</div>} subtitle="ma" big={true} />
          <CharacterBox contents={<div>மி</div>} subtitle="mih" big={true} />
          <br />
          <CharacterBox contents={<div>ப</div>} subtitle="pa" big={true} />
          <CharacterBox contents={<div>பி</div>} subtitle="pih" big={true} />
          <div className="lang-step">
            Sometimes the vowel modifier changes the shape of the consonant;
            sometimes it appears as a character before or after or on both sides
            of the consonant. Example: The vowel modifier that adds the -ah
            sound comes after its consonant.
          </div>
          <div className="lang-step">
            <CharacterBox contents={<div>ம</div>} subtitle="ma" big={true} />
            <CharacterBox contents={<div>மா</div>} subtitle="mah" big={true} />
          </div>
          <div className="lang-step">
            The modifier that adds the -ai sound comes before.
          </div>
          <div className="lang-step">
            <CharacterBox contents={<div>ம</div>} subtitle="ma" big={true} />
            <CharacterBox contents={<div>மை</div>} subtitle="mai" big={true} />
          </div>
          <div className="lang-step">
            Most vowels in Tamil are fairly regular; once you learn how they
            modify one consonant, you can follow the pattern for all the other
            consonants. (There are a few irregular vowels, too, so watch out for
            those).
          </div>
          <h3> Lesson 5: What's up with the red letter? </h3>
          <div className="lang-step">
            Sorry, this one you just have to memorize. There's two consonants
            that look similar to each other:
          </div>
          <div className="lang-step">
            <CharacterBox contents={<div>ம</div>} subtitle="ma" big={true} />
            <CharacterBox contents={<div>ழ</div>} subtitle="zha" big={true} />
          </div>
          <div className="lang-step">
            This second one isn't a modified ம; it's just a different consonant
            base form.
          </div>
          <h3> Lesson 6: Apply What You've Learned</h3>
          <div className="lang-step">
            Take a look at this picture of a store in Chennai. The store name is
            written in both English and in Tamil. How many of the letters can
            you figure out?{" "}
          </div>
          <img
            src="indira_stores_tamil10.jpg"
            alt="Store in English and Tamil"
          ></img>
          <div className="lang-step">
            Sometimes font differences can be confusing, so here's the same text
            written in our web font:
          </div>
          <CharacterBox
            contents={<div>S. இந்திரா சூப்பர் மார்க்கெட்</div>}
            subtitle="S. Indira Super Market"
            big={true}
          />
          <div className="lang-step">
            How many could you figure out? If you figured out more than one,
            you're doing great! Either way, you are finished with this lesson!
            You may return to the play.
          </div>
          <br />
          <a className="blue-link" href="..">
            Main page
          </a>
        </div>
      )}
    </div>
  );
}

function LanguageSelect(props: {
  name: string;
  subtitle: string;
  onClick: () => void;
  isLangSelected: boolean;
}) {
  return (
    <div className="lang-select-outer">
      <div
        className={classNames("lang-select-inner", {
          "lang-select-selected": props.isLangSelected,
        })}
        onClick={props.onClick}
      >
        <div className="lang-select-name">{props.name}</div>
        <div className="lang-select-subtitle">{props.subtitle}</div>
      </div>
    </div>
  );
}

function CharacterBox(props: {
  contents: JSX.Element;
  subtitle: string;
  big: boolean;
  loose?: boolean;
}) {
  //const
  return (
    <div className="character-box-outer">
      <div className="character-box-inner">
        <div
          className={classNames("character-box-contents", {
            big: props.big,
            loose: !!props.loose,
          })}
        >
          {props.contents}
        </div>
      </div>
      <div className="character-box-subtitle">{props.subtitle}</div>
    </div>
  );
}

function MaLetter(props: { onClick: () => void; isSelected: boolean }) {
  //const
  return (
    <span
      className={classNames("ma", { "ma-selected": props.isSelected })}
      onClick={props.onClick}
    >
      ம
    </span>
  );
}

function MaIshLetter(props: {
  onClick: () => void;
  isSelected: boolean;
  letter: string;
}) {
  //const
  return (
    <span
      className={classNames("ma", "ma-ish", {
        "ma-selected": props.isSelected,
      })}
      onClick={props.onClick}
    >
      {props.letter}
    </span>
  );
}

function NotMaLetter(props: {
  onClick: () => void;
  isSelected: boolean;
  letter: string;
}) {
  //const
  return (
    <span
      className={classNames("ma", { "not-ma-selected": props.isSelected })}
      onClick={props.onClick}
    >
      {props.letter}
    </span>
  );
}

export default Languages;
