import React, { useEffect, useState } from "react";
import interact from "interactjs";
import classNames from "classnames";

function Bespoke() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  const [step, setStep] = useState(0);
  const [idlis, setIdlis] = useState(0);
  const [cherries, setCherries] = useState(0);

  return (
    <div>
      <div className="bespoke-canvas">
        <div className="ingredient-box">
          <div className="leaf-bound">
            <img
              className="leaf draggable leaf-dropzone"
              src="banana_leaf.png"
              alt="banana leaf"
            ></img>
          </div>
          <div className="food-label">banana leaf</div>

          <div className="idli-box">
            <img
              className="draggable droppable leaf-droppable idli idli-dropzone"
              src="idli1.png"
              alt="idli"
            ></img>

            <img
              className="draggable droppable leaf-droppable idli idli-dropzone"
              src="idli2.png"
              alt="idli"
            ></img>
          </div>

          <div className="food-label">millet idlis</div>

          <div className="cherry-box">
            <img
              className="draggable droppable idli-droppable cherry cherry1"
              src="cherry1.png"
              alt="cherry"
            ></img>

            <img
              className="draggable droppable idli-droppable cherry cherry2"
              src="cherry2.png"
              alt="cherry"
            ></img>
          </div>

          <div className="food-label">dried cherries</div>

          <div className="draggable droppable cup cup-dropzone">
            <img
              className="tumbler"
              src="tumbler_with_milk.png"
              alt="metal cup with milk"
            ></img>
            <div id="milk"></div>
          </div>

          <div className="food-label">milk</div>

          <div className="draggable droppable cup-droppable chocolate">
            <img
              className="hot-chocolate"
              src="hot_chocolate.png"
              alt="a scoop of mint hot chocolate powder"
            ></img>
          </div>

          <div className="food-label">mint hot chocolate</div>
        </div>
      </div>
      <div className="bespoke-instructions">
        <h2>A Bespoke Meal</h2>
        <div id="bespoke-instructions-div">
          {" "}
          <p>
            {" "}
            We have prepared a special meal for you with an avant-garde but
            delicious combination of ingredients. This has been one of our
            favorite breakfasts in the last six months. Some assembly required.{" "}
          </p>{" "}
          Instructions:{" "}
        </div>
        <ol>
          <li className={classNames({ highlight: step === 1 })}>
            1. &nbsp;Take a banana leaf (click and drag it out of the ingredient
            box).
          </li>
          <li className={classNames({ highlight: step === 2 })}>
            2. &nbsp;Put some idlis on the banana leaf.
          </li>
          <li className={classNames({ highlight: step === 3 })}>
            3. &nbsp;Put one cherry on each idli.
          </li>
          <li className={classNames({ highlight: step === 4 })}>
            4. &nbsp;Take a tumbler of hot milk.
          </li>
          <li className={classNames({ highlight: step === 5 })}>
            5. &nbsp;Stir the mint hot chocolate powder into the milk and stir.
          </li>
          <li className={classNames({ highlight: step === 6 })}>
            6. &nbsp;When your meal is instagram-ready...enjoy!
          </li>
        </ol>
      </div>

      <p> Once you're done, return to reading the play!</p>
      <p>
        <a className="blue-link" href="..">
          Main page
        </a>
      </p>
    </div>
  );
}

// target elements with the "draggable" class
interact(".draggable").draggable({
  // enable inertial throwing
  inertia: true,
  // keep the element within the area of its parent
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: ".bespoke-canvas",
      endOnly: true,
    }),
  ],
  // enable autoScroll
  // autoScroll: true,

  listeners: {
    // call this function on every dragmove event
    move: dragMoveListener,

    // call this function on every dragend event
    end(event) {
      var textEl = event.target.querySelector("p");

      textEl &&
        (textEl.textContent =
          "moved a distance of " +
          Math.sqrt(
            (Math.pow(event.pageX - event.x0, 2) +
              Math.pow(event.pageY - event.y0, 2)) |
              0
          ).toFixed(2) +
          "px");
    },
  },
});

function dragMoveListener(event: any) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

  // translate the element
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + y + "px)";

  // update the position attributes
  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);

  if (x > 30) {
    target.classList.add("widen");
  }
  if (x <= 40) {
    target.classList.remove("widen");
  }
}

// enable draggables to be dropped into this
interact(".leaf-dropzone").dropzone({
  // only accept elements matching this CSS selector
  accept: ".leaf-droppable",
  // Require a 75% element overlap for a drop to be possible
  overlap: 0.25,

  // listen for drop related events:

  ondropactivate: function (event) {
    // add active dropzone feedback
    event.target.classList.add("drop-active");
  },
  ondragenter: function (event) {
    var draggableElement = event.relatedTarget;
    var dropzoneElement = event.target;

    // feedback the possibility of a drop
    dropzoneElement.classList.add("drop-target");
    draggableElement.classList.add("can-drop");
  },
  ondragleave: function (event) {
    // remove the drop feedback style
    event.target.classList.remove("drop-target");
    event.relatedTarget.classList.remove("can-drop");
    // event.relatedTarget.textContent = "Dragged out";
  },
  ondrop: function (event) {
    /* setIdlis(idlis + 1);
     * if (idlis > 1 && step === 1) {
     *   setStep(2);
     * }*/
    // event.relatedTarget.textContent = "Dropped";
  },
  ondropdeactivate: function (event) {
    // remove active dropzone feedback
    event.target.classList.remove("drop-active");
    event.target.classList.remove("drop-target");
  },
});

// enable draggables to be dropped into this
interact(".idli-dropzone").dropzone({
  // only accept elements matching this CSS selector
  accept: ".idli-droppable",
  // Require a 75% element overlap for a drop to be possible
  overlap: 0.25,

  // listen for drop related events:

  ondropactivate: function (event) {
    // add active dropzone feedback
    event.target.classList.add("drop-active");
  },
  ondragenter: function (event) {
    var draggableElement = event.relatedTarget;
    var dropzoneElement = event.target;

    // feedback the possibility of a drop
    dropzoneElement.classList.add("drop-target");
    draggableElement.classList.add("can-drop");
  },
  ondragleave: function (event) {
    // remove the drop feedback style
    event.target.classList.remove("drop-target");
    event.relatedTarget.classList.remove("can-drop");
    // event.relatedTarget.textContent = "Dragged out";
  },
  ondrop: function (event) {
    /* setIdlis(idlis + 1);
     * if (idlis > 1 && step === 1) {
     *   setStep(2);
     * }*/
    // event.relatedTarget.textContent = "Dropped";
  },
  ondropdeactivate: function (event) {
    // remove active dropzone feedback
    event.target.classList.remove("drop-active");
    event.target.classList.remove("drop-target");
  },
});

// enable draggables to be dropped into this
interact(".cup-dropzone").dropzone({
  // only accept elements matching this CSS selector
  accept: ".cup-droppable",
  // Require a 75% element overlap for a drop to be possible
  overlap: 0.25,

  // listen for drop related events:
  ondropactivate: function (event) {
    // add active dropzone feedback
    event.target.classList.add("drop-active");
  },
  ondragenter: function (event) {
    var draggableElement = event.relatedTarget;
    var dropzoneElement = event.target;

    // feedback the possibility of a drop
    dropzoneElement.classList.add("drop-target");
    dropzoneElement.classList.add("z2");
    draggableElement.classList.add("can-drop");
    draggableElement.classList.add("hide");
    document.getElementById("milk")!.classList.add("brownen");
  },
  ondragleave: function (event) {
    // remove the drop feedback style
    event.target.classList.remove("drop-target");
    event.target.classList.remove("z2");
    event.relatedTarget.classList.remove("can-drop");
    event.relatedTarget.classList.remove("hide");
  },
  ondrop: function (event) {
    // event.relatedTarget.textContent = "Dropped";
  },
  ondropdeactivate: function (event) {
    // remove active dropzone feedback
    event.target.classList.remove("drop-active");
    event.target.classList.remove("drop-target");
  },
});

// this function is used later in the resizing and gesture demos
// @ts-ignore
// window.dragMoveListener = dragMoveListener;

export default Bespoke;
