import React, { useEffect } from "react";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import { Star } from "react-feather";

import Bespoke from "./bespoke";
import Languages from "./languages";
import Family from "./family";

import "./styles.css";
import "./reception.css";
import "./github-markdown.css";
import classNames from "classnames";

const BACKGROUND_IMAGE_CLASS_NAMES = [
  "bi-a",
  "bi-b",
  "bi-c",
  "bi-d",
  "bi-e",
  "bi-f",
  "bi-g",
  "bi-h",
];

function App() {
  /* if (
   *   window.location.href.includes("aweddingplay") &&
   *   !window.location.href.includes("video")
   * ) {
   *   return (
   *     <div className="outer-container">
   *       <div className="container-fluid">
   *         <h2>hello!</h2>
   *         <p>
   *           If you are here for Rishi & Tharu's wedding, you are in the right
   *           place. Please check back here on Sunday.
   *         </p>
   *       </div>
   *     </div>
   *   );
   * }*/

  // Two different styles: 2020 wedding website, and 2023 reception website.
  // This flips between them.
  const location = useLocation();
  const isCeremonyPathname = [
    "/ceremony",
    "/bespoke",
    "/datamatch",
    "/languages",
    "/family",
    "/online",
    "/video",
    "/knees",
    "/photos",
    "/hello",
  ].includes(location.pathname);

  const backgroundImageClassName =
    BACKGROUND_IMAGE_CLASS_NAMES[
      Date.now() % BACKGROUND_IMAGE_CLASS_NAMES.length
    ];

  return (
    <div
      className={classNames({
        "body-replacement": isCeremonyPathname,
        "body-reception": !isCeremonyPathname,
        [backgroundImageClassName]: !isCeremonyPathname,
      })}
    >
      <div
        className={classNames({
          "outer-container": isCeremonyPathname,
          "reception-outer": !isCeremonyPathname,
        })}
      >
        <div
          className={classNames({
            "container-fluid": isCeremonyPathname,
            "reception-inner": !isCeremonyPathname,
          })}
        >
          <div>
            {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
            <Switch>
              <Route exact path="/">
                <Reception />
              </Route>
              <Route path="/reception">
                <Reception />
              </Route>
              <Route path="/epilogue">
                <Epilogue />
              </Route>
              <Route exact path="/ceremony">
                <Home />
              </Route>
              <Route path="/bespoke">
                <Bespoke />
              </Route>
              <Route path="/datamatch">
                <Datamatch />
              </Route>
              <Route path="/languages">
                <Languages />
              </Route>
              <Route path="/family">
                <Family />
              </Route>
              <Route path="/online">
                <Online />
              </Route>
              <Route path="/video">
                <Video />
              </Route>
              <Route path="/knees">
                <Knees />
              </Route>
              <Route path="/photos">
                <Photos />
              </Route>
              <Route path="/hello">
                <Hello />
              </Route>
              <Route path="*">
                <FourOhFour />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

function Reception() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  const [visibleAccordianSection, setVisibleAccordianSection] = React.useState<
    string | null
  >(null);

  const showAccordianSection = (section: string) => {
    if (section === visibleAccordianSection) {
      setVisibleAccordianSection(null);
    } else {
      setVisibleAccordianSection(section);
    }
  };

  const toggleDressCode = () => {
    showAccordianSection("dressCode");
  };
  const toggleGettingThere = () => {
    showAccordianSection("gettingThere");
  };

  return (
    <div id="reception">
      <h1 className="center">Tharu & Rishi's Wedding Reception</h1>
      <a
        href="https://airtable.com/appnsrefdhhDbPljx/shrBpKa023dTmBu1L"
        rel="noopener noreferrer"
        className="center rsvp-link"
      >
        RSVP now
      </a>

      <h2>Schedule of Events</h2>
      <p>
        The reception will take place over the weekend of September 23, in
        Berkeley.
      </p>
      {/* <p>
       <ClipboardIcon className="icon icon-star" strokeWidth={1.3} size={18} />{" "}
        Primary events. Saturday morning is the "official" event. If you're
        thinking of coming to 1-2 additional events but aren't sure which ones,
        Saturday evening and/or Sunday morning are potentially the next ones to
        consider.
      </p> */}
      {/* <div className="toggle" onClick={toggleDressCode}>
        <input type="checkbox" id="showDetails" />
        <label htmlFor="showDetails">Show logistics details</label>
      </div> */}

      <h4 className="schedule-h3">
        <div>
          Saturday morning{" "}
          <Star className="icon icon-star" strokeWidth={1.3} size={18} />
          {/* <Radio className="icon icon-star" strokeWidth={1.3} size={20} /> */}
        </div>
        {/* <div>Sep 23, 9a&ndash;2p</div> */}
        <div>
          {/* <Star className="icon icon-star" strokeWidth={1.3} size={18} />
          <Star className="icon icon-star" strokeWidth={1.3} size={18} /> */}
        </div>
      </h4>
      <div className="subtitle">
        <div>
          <a
            className="reception-a"
            href="https://thefreight.org/visit/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Freight & Salvage
          </a>
          , doors open 9:30a, show starts at 10:30a
        </div>
        <div>
          {/* <Star className="icon icon-star" strokeWidth={1.3} size={18} />
          <Star className="icon icon-star" strokeWidth={1.3} size={18} /> */}
        </div>
      </div>
      <div>
        <p>
          The main event! The show will run 1-2 hours with an intermission,
          followed by socializing, activities, and food.
        </p>
        <p>
          Here is a{" "}
          <a
            className="reception-a"
            href="https://www.spotangels.com/map?spLng=-122.2696&spLat=37.87074&spAddress=2020%20Addison%20Street,%20Berkeley,%20California%2094704,%20United%20States&lng=-122.27030611775814&lat=37.872063653082066&zoom=16.046397150432174&product=parking&arrival=1695486600000&departure=1695506400000"
            target="_blank"
            rel="noopener noreferrer"
          >
            map of the parking options
          </a>
          . Note that the garage right next to the venue is also the most
          expensive option. The venue is 1 block from the Downtown Berkeley BART
          station, so public transit is also a great option.
        </p>
        {/* There will be also
        opportunities to meet each other, our families, and us. There is a
        substantial lobby in case you need to take a break from the show (e.g.
        for kid reasons). There will be food. */}
        {/* <span className="reception-a">
          Click for details{" "}
          <ChevronRight className="icon" strokeWidth={3} size={18} />
        </span> */}
      </div>
      {/* <span className="reception-a" onClick={toggleDressCode}>
        Getting there
      </span>{" "}
      | <span className="reception-a">Dress code</span> |{" "}
      <span className="reception-a">FAQ</span> */}
      {/* <div className="accordian-container">
        <div className="accordian-button" onClick={toggleDressCode}>
          Dress code <ChevronDown className="icon" strokeWidth={1} size={24} />
        </div>
        <div className="accordian-button" onClick={toggleGettingThere}>
          Getting there{" "}
          <ChevronDown className="icon" strokeWidth={1} size={24} />
        </div>
      </div> */}
      {visibleAccordianSection === "dressCode" && (
        <div>
          <i>Dress code</i>: What you might wear to the theater. Bonus points if
          the theater you're imagining is located in India (aka if you're
          deciding between wearing something colorful and something neutral,
          wear the colorful outfit).
        </div>
      )}
      {visibleAccordianSection === "gettingThere" && (
        <div>
          <i>Getting there</i>: Freight and Salvage is 1 block from the Downtown
          Berkeley BART station. If you drive, there is a parking garage next
          door, or you can usually find street parking within a 10 minute
          walking radius (e.g. try Oxford St, north of Hearst).
        </div>
      )}
      <h4 className="schedule-h3">
        <div>
          Saturday afternoon (knee)
          {/* <Sun className="icon icon-star" strokeWidth={1.4} size={18} /> */}
        </div>
      </h4>
      <div className="subtitle">
        <a
          className="reception-a"
          href="https://goo.gl/maps/y6cgTa81zazZjnFu6"
          target="_blank"
          rel="noopener noreferrer"
        >
          North Berkeley
        </a>
      </div>
      <div>
        Self-guided wandering tour of some cool corners of North Berkeley. If
        you'd rather go in a group, leave from in front of our house at 2:45pm.
      </div>
      {/* <div>
        Getting here: The start is a bit over a mile from the venue, and the
        route will probably be a (hilly) mile or two. There is a bus that will
        get you from the venue to the start. There is a reasonable amount of
        street parking along the route.
      </div> */}
      <h4 className="schedule-h3">
        <div>
          Saturday evening{" "}
          {/* <ClipboardIcon
            className="icon icon-star"
            strokeWidth={1.3}
            size={18}
          /> */}
          {/* <ShoppingCart
            className="icon icon-star"
            strokeWidth={1.5}
            size={16}
          /> */}
        </div>
        <div>
          {/* <Star className="icon icon-star" strokeWidth={1.3} size={18} /> */}
        </div>
      </h4>
      <div className="subtitle">Berkeley, SF, and South Bay, 5pm&ndash;</div>
      <div>
        Small group dinner parties across the Bay Area. We'll provide the menu,
        you'll put it all together. You'll be making rasam, a vegetable dish,
        and rice.
      </div>
      <h4 className="schedule-h3">
        <div>
          Sunday morning{" "}
          {/* <Star className="icon icon-star" strokeWidth={1.3} size={18} /> */}
          {/* <Map className="icon icon-star" strokeWidth={1.3} size={16} /> */}
        </div>
        <div>
          {/* <Star className="icon icon-star" strokeWidth={1.3} size={18} /> */}
        </div>
      </h4>
      <div className="subtitle">
        <div>
          <a
            className="reception-a"
            href="https://goo.gl/maps/oqskzC48mzK2AuoU9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Social sciences building
          </a>
          , UC Berkeley, 10am (but flexible)
        </div>
      </div>
      <div>
        <p>
          Major update: this is now an official{" "}
          <a
            className="reception-a"
            href="https://baoc.org/wiki/Schedule/2023/UC_Berkeley_2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bay Area Orienteering Club event
          </a>
          ! We recommend arriving between 9:45am and 10:45am.
        </p>
        <p>
          The course is just under 3 miles long. It is possible to run it, and
          also possible to stroll it non-competitively; you'll be in good
          company either way. It is also fine to only do part of the course. We
          recommend groups of 1&#8209;2 for running, and 1&#8209;4 for walking.
        </p>
        <p>
          We will be hanging out at a table near registration for before and
          after you head out on your course!
        </p>
        {/* navigational challenge around the Berkeley campus.  There will be a
        non-competitive format for groups of 1&#8209;4, a competitive format for
        groups of 1&#8209;2, and a stroller/wheelchair-friendly version of the
        course. The course is around 2&#8209;3 miles, and we'll provide an
        abridged version if you just want a taste (no athletic interest or
        ability required!) */}
      </div>
      {/* <div>
        Getting here: It's a 14 minute walk from the Downtown Berkeley BART. If
        you're driving, Sproul Lot is a good place to park, and there is free
        street parking once you get south of Dwight.
      </div> */}
      <h4 className="schedule-h3">
        <div>
          Sunday afternoon{" "}
          {/* <Dribbble className="icon icon-star" strokeWidth={1.3} size={18} /> */}
        </div>
        <div></div>
      </h4>
      <div className="subtitle">
        <div>
          <a
            className="reception-a"
            href="https://maps.app.goo.gl/d2HWApC85KVLh9bo6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sway
          </a>
          , Berkeley, 2-6pm
        </div>
      </div>
      <div className="subtitle">
        Hang out with us in a relaxed setting. There will be a collaborative art
        project, Eva's birthday party, a jigsaw puzzle, and if we're lucky, an
        exam. You can pick and choose which of these you'd like to do.
      </div>

      <h4 className="schedule-h3">
        <div>
          Monday onwards
          {/* <HomeIcon className="icon icon-star" strokeWidth={1.3} size={18} /> */}
        </div>
      </h4>
      <div className="subtitle">
        <div>
          <a
            className="reception-a"
            href="https://goo.gl/maps/yAy7L77rbob8rjPWA"
            target="_blank"
            rel="noopener noreferrer"
          >
            Our house
          </a>
          , North Berkeley
        </div>
      </div>
      <div>
        Come by and say hi to us or our families if you're still around!
        {/* Maybe we'll put out a jigsaw puzzle, or work on a puzzle hunt? */}
      </div>
      {/* <div className="center header-top"></div>
      <div className="center header-bottom">Weekend of Sep 23, 2023</div>
      <p>
        Hello everyone! We'd like to invite you to our in-person wedding
        reception this fall. The main event will take place in the morning of
        September 23, through lunch. There will also be optional, smaller events
        from Friday evening through Sunday evening.
      </p>
      <p>
        Expect more information as we head into September. Feel free to email us
        if there are details that would help with planning!
      </p> */}
      <p></p>
      <hr></hr>
      <div className="knee-container">
        <p>
          Wedding <i>reception</i>? What happened to the wedding?
          <ul>
            <li>
              <a
                className="reception-a"
                href="/ceremony"
                target="_self"
                rel="noopener noreferrer"
              >
                Original wedding website
              </a>
            </li>
            <li>
              <a
                className="reception-a"
                href="/knees"
                target="_self"
                rel="noopener noreferrer"
              >
                List of knees
              </a>
            </li>
          </ul>
        </p>
        {/* <div className="center">
          <Tablet target="/bespoke" name="Bespoke" number="I" />
          <Tablet target="/datamatch" name="Datamatch" number="II" />
          <Tablet target="/languages" name="Languages" number="III" />
          <Tablet target="/family" name="Family" number="IV" />
        </div> */}
      </div>
    </div>
  );
}

function Epilogue() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  return (
    <div id="reception" className="epilogue">
      <h1>Rishi & Tharu In The Year 2100</h1>

      <h2>Scene 1</h2>
      <div className="scene-grid">
        <div>T:</div>
        <div>O my husband!</div>
        <div>R:</div>
        <div>
          <div className="scene-space"></div>O my wife!
        </div>
        <div>T:</div>
        <div>What do you regret in life?</div>
        <div>R:</div>
        <div>O my partner!</div>
        <div>T:</div>
        <div>
          <div className="scene-space"></div>O my spouse!
        </div>
        <div>R:</div>
        <div>I wish we'd had a smaller house</div>
        <div>T:</div>
        <div>A SMALLER house?</div>
        <div>R:</div>
        <div>
          <div className="scene-space"></div>A smaller house.
        </div>
        <div></div>
        <div>I wish we'd had a smaller house.</div>
        <div></div>
        <div>All that time spent vacuum cleaning</div>
        <div></div>
        <div>When we could have been careening</div>
        <div></div>
        <div>out about the city square,</div>
        <div></div>
        <div>gallivanting everywhere.</div>
        <div>T:</div>{" "}
        <div>
          <div className="scene-space"></div>I remember differently,
        </div>
        <div></div>
        <div>Adventures interspersed with sprees</div>
        <div></div>
        <div>and lots of stays in B-n-Bs</div>
        <div>R:</div> <div>I do recall the time we went</div>
        <div></div>
        <div>To Greece — a week that was well spent.</div>
        <div></div>
        <div>It was for my friend's second wedding, </div>
        <div></div>
        <div>Thessaloniki — a pretty setting.</div>
        <div>T:</div> Don't forget, most recently,
        <div></div>
        <div>We went to see the colony.</div>
        <div></div>
        <div>The one on Mars. It was so cold!</div>
        <div>R:</div> <div>I never thought I'd be so old</div>
        <div></div>
        <div>when I first tried space travel. </div>
        <div>T:</div>{" "}
        <div>
          <div className="scene-space"></div>Brr!
        </div>
        <div></div>
        <div>We should have gone there wrapped in fur. </div>
        <div>R:</div> <div>The trip was long, the food was bad,</div>
        <div></div>
        <div>It was the longest trip I've had.</div>
        <div>T:</div> <div>But we did see our sprightly friends,</div>
        <div></div>
        <div>The ones who'd travel to the ends </div>
        <div></div>
        <div>of Earth and back. And to the stars!</div>
        <div>R:</div> <div>I guess they did end up on Mars.</div>
      </div>

      <h2>Scene 2</h2>
      <div className="scene-grid">
        <div>R:</div> <div>And O my wife, I wish our tribe </div>
        <div></div>
        <div>could have more children. Maybe... five?</div>
        <div>T:</div> <div>O my husband, five's a lot. </div>
        <div></div> <div>Think of all the pans and pots </div>
        <div></div>
        <div>we would have needed just to cook </div>
        <div></div>
        <div>one meal. No more smaller house!</div>
        <div>R:</div> <div>They were a handful at the start</div>
        <div></div>
        <div>But over time they learned the art</div>
        <div></div>
        <div>Of self sufficiency.</div>
        <div>T:</div>{" "}
        <div>
          <div className="scene-space"></div>Maybe!
        </div>
        <div></div>
        <div>Just last week our oldest called</div>
        <div></div>
        <div>To ask me how to use a mouse.</div>
        <div>R:</div> <div>If you had to rate the whole </div>
        <div></div>
        <div>experience of having kids, </div>
        <div></div>
        <div>what would you say?</div>
        <div>T:</div> <div>Ten out of ten! Well, ok,</div>
        <div></div>
        <div>Nine point five, call it a day.</div>
        <div>R:</div> <div>They were really pretty great! </div>
        <div></div>
        <div>I would vote for nine point eight.</div>
        <div>T:</div> <div>I could have skipped the drama from</div>
        <div></div>
        <div>The year two thousand thirty-six.</div>
        <div>R:</div> <div>With all the fuss about the clothes?</div>
        <div>T:</div> <div>I'd call it AI-fashion-gate.</div>
        <div></div>
        <div>I'm glad that's over. By the way,</div>
        <div></div>
        <div>I can't believe they moved away.</div>
        <div></div>
        <div>Why not live somewhere close by car?</div>
        <div>R:</div> <div>Yeah! Isn't Mars a little far?</div>
      </div>

      <h2>Scene 3</h2>
      <div className="scene-grid">
        <div>T:</div> <div>O, husband dear</div>
        <div>R:</div> <div>O, wifely one</div>
        <div>T:</div> <div>I don't wish that we'd had more fun.</div>
        <div></div>
        <div>I think our lives were rich and full</div>
        <div></div>
        <div>And rarely troubled by the bull</div>
        <div></div>
        <div>that could've happened. Don't you think?</div>
        <div>R:</div> <div>It all went by in just a blink</div>
        <div></div>
        <div>Of my left eye.</div>
        <div>T:</div> <div>What of your right?</div>
        <div>R:</div> <div>It's been replaced by CGI, </div>
        <div></div>
        <div>It doesn't count. </div>
        <div>T:</div> <div><div className="scene-half-space"></div>I see.</div>
        <div>R:</div> <div><div className="scene-space"></div>I don't.</div>
        <div></div>
        <div>But let me not complain. It's true</div>
        <div></div>
        <div>The world has changed for me and you.</div>
        <div>T:</div> <div>Well, life is very long, I say.</div>
        <div>R:</div> <div>I'm glad things went a certain way,</div>
        <div></div>
        <div>And that we played our part.</div>
        <div>T:</div> <div><div className="scene-space"></div>You mean...</div>
        <div>R:</div> <div>Mosquitoes! All eliminated.</div>
        <div></div>
        <div>My younger self would be elated.</div>
        <div>T:</div> <div>No more bites upon my knees</div>
        <div></div>
        <div>On summer nights.</div>
        <div>R:</div> <div><div className="scene-space"></div>No more disease!</div>
        <div></div>
        <div>It took nine hundred billion nets </div>
        <div></div>
        <div>But one by one we trapped them all</div>
        <div></div>
        <div>And I for one have no regrets.</div>
        <div>T:</div> <div>Nor I. It was no small thing</div>
        <div></div>
        <div>Humanity accomplished.</div>
        <div>R:</div> <div>Yes! Our children's generation,</div>
        <div></div>
        <div>And all the robots in the nation</div>
        <div></div>
        <div>Can live a longer, freer life.</div>
        <div>T:</div> <div>And on that note, an inquisition.</div>
        <div></div>
        <div>Isn't it time for intermission?</div>
      </div>
    </div>
  );
}

function Home() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  return (
    <div>
      <h1 className="center">
        Tharu and Rishi
        <br />
        get married: a website
      </h1>
      <p className="tiny center">September 27, 2020</p>
      <p className="pt1">HELLO and welcome!</p>
      <h2>Details of the wedding</h2>
      <div className="qblock">
        <div className="question inline">DATE:</div> September 27, 2020
      </div>
      <div className="qblock">
        <div className="question inline">TIME:</div> 10 a.m. US Pacific Time or
        9:30 p.m. India Time
      </div>
      <div className="qblock">
        <div className="question inline">PICTURE OF BRIDE AND GROOM:</div>
        <img
          className="family-image2"
          src="rtpic_tiny.jpg"
          alt="rishi and tharu"
        ></img>{" "}
      </div>
      <div className="qblock">
        <div className="question inline">LOCATION:</div> Wherever you are{" "}
      </div>
      <div className="qblock">
        <div className="question inline">
          WHAT TO DO AT THE AFOREMENTIONED DATE, TIME, ETC:
        </div>{" "}
        Open the package and start reading!
      </div>
      <div className="qblock">
        <div className="question inline">WHAT PACKAGE:</div> If you did not
        receive a package, please let us know immediately.
      </div>
      <div className="qblock">
        <div className="question inline">THEME SONG:</div> Einstein on the Beach
        (serious) & Fiddle Faddle (celebratory){" "}
      </div>
      <div className="qblock">
        <div className="question inline">DRESS CODE:</div> Dreamscape
      </div>
      <div className="qblock">
        <div className="question inline">DREAM WHAT?</div> Wear what you love!
        Then send us pictures (optional).
      </div>
      <div className="qblock">
        <div className="question inline">PICTURE OF THARU'S FAMILY:</div>
        <a
          href="https://photos.app.goo.gl/rVSbTiYcozAhWb1N9"
          target="_blank"
          rel="noopener noreferrer"
          className="family-image-container inline"
        >
          <img
            className="family-image"
            src="tfpic_small.jpg"
            alt="tharu's family"
          ></img>{" "}
          <i className="fa fa-external-link fa-2x family-image-link"></i>
        </a>
      </div>
      <div className="qblock">
        <div className="question inline">PICTURE OF RISHI'S FAMILY:</div>
        <a
          href="https://photos.app.goo.gl/SqHDPNTsaUUbMDm68"
          target="_blank"
          rel="noopener noreferrer"
          className="family-image-container inline"
        >
          <img
            className="family-image"
            src="rfpic_small.jpg"
            alt="rishi's family"
          ></img>{" "}
          <i className="fa fa-external-link fa-2x family-image-link"></i>
        </a>
      </div>
      Click on the family pictures for more photos. Or go{" "}
      <a href="/photos" className="blue-link">
        here
      </a>{" "}
      to add your own!
      {/* <div className="qblock">
          <div className="question inline">WHERE ARE THE KNEES</div> Available
          below starting September 27.
          </div>

          <Tablet target="/bespoke" name="Bespoke" number="I" />
          <Tablet target="/datamatch" name="Datamatch" number="II" />
          <Tablet target="/languages" name="Languages" number="III" />
          <Tablet target="/family" name="Family" number="IV" /> */}
    </div>
  );
}

function Datamatch() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  return (
    <div>
      <h2>Datamatch</h2>
      <div className="datamatch-warning">This event was in the past</div>
      <p>
        In this activity, you will get to experience a little bit of Datamatch
        for yourself! (Note: This is not a romantic activity. Our algorithm will
        match you for the purpose of conversation, not for the purpose of
        dating.)
      </p>

      <p>
        We consulted our wisest family (and some friends) on what factors lead
        to good conversational compatibility between two people. They have
        designed a questionnaire, provided for you at the link below. Please
        fill it out! The form closes at 11:20 AM Pacific Time.{" "}
      </p>
      <p>
        Once everyone has filled out the form, we will email you with three
        matches. Then, at 12 PM (Pacific Time), you and your matches will call
        each other on the phone. We hope this will be a fun way for you to get
        to know a few people at the wedding.
      </p>
      <p> Please click this link to begin:</p>
      <p>
        <a
          href="https://airtable.com/shrcvG73v1Yfmrl1b"
          target="_blank"
          rel="noopener noreferrer"
          className="datamatch-form"
        >
          Datamatch Form
        </a>
      </p>
      <p>
        {" "}
        P.S. If you don't want to be matched or can't call a US phone number,
        you can still answer the questions and opt out of the second part of the
        activity.
      </p>
    </div>
  );
}

function Online() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  return (
    <div className="online">
      <h2>An online copy</h2>

      <p>
        We're sorry we were not able to get the wedding package to you in time
        for the wedding. We have posted the contents of the package at the links
        below.
      </p>
      <p>
        Due to the personal nature of the content, we are trying as hard as we
        can to keep electronic copies of the play off of the internet. Please
        ask us before sharing the links below.
      </p>

      <p>
        <a
          href="https://drive.google.com/file/d/1YyQm0FoAvOcC4RdySxYtVvbACmBmSQPv/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instructions
        </a>{" "}
        (Read online or print)
      </p>
      <p>
        <a
          href="https://drive.google.com/file/d/1auFbl6jZRGKTGEJ9inq6fDcl2HQrwGiY/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sentiment card
        </a>{" "}
        (Print or copy to a piece of paper by hand)
      </p>
      <p>
        <a
          href="https://drive.google.com/file/d/1wsYyLuEJ8VQClKyuuAW7RqhkCpt54lU2/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bookmarks
        </a>{" "}
        (Referenced in the instructions)
      </p>
      <p>
        <a
          href="https://drive.google.com/file/d/1wC77ZEWe1eZY4rzBMxaB9vBLM55fQXlC/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          A Wedding of the Imagination
        </a>{" "}
        (Read online)
      </p>
    </div>
  );
}

function Video() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );
  return (
    <div>
      <h1 className="center">
        Tharu and Rishi
        <br />
        get married: a website
      </h1>
      <p className="tiny center">September 27, 2020</p>
      <p className="pt1">Hello and welcome!</p>
      <h2>Details of the pre-wedding video</h2>
      <div className="qblock">
        <div className="question inline">DATE:</div> September 27, 2020
      </div>
      <div className="qblock">
        <div className="question inline">TIME:</div> 8 a.m. US Pacific Time or
        8:30 p.m. India Time
      </div>
      <div className="qblock">
        <div className="question inline">LINK:</div>
        <a
          href="https://www.facebook.com/permalink.php?story_fbid=101334155074073&id=101296738411148"
          target="_blank"
          rel="noopener noreferrer"
          className="blue-link"
        >
          Facebook Livestream
        </a>
      </div>
      <div className="qblock">
        <div className="question inline">PICTURE OF BRIDE AND GROOM:</div>
        <img
          className="family-image2"
          src="rtpic_tiny.jpg"
          alt="rishi and tharu"
        ></img>{" "}
      </div>
      <div className="qblock">
        <div className="question inline">PICTURE OF THARU'S FAMILY:</div>
        <a
          href="https://photos.app.goo.gl/rVSbTiYcozAhWb1N9"
          target="_blank"
          rel="noopener noreferrer"
          className="family-image-container inline"
        >
          {" "}
          <img
            className="family-image"
            src="tfpic_small.jpg"
            alt="tharu's family"
          ></img>{" "}
          <i className="fa fa-external-link fa-2x family-image-link"></i>
        </a>
      </div>
      <div className="qblock">
        <div className="question inline">PICTURE OF RISHI'S FAMILY:</div>
        <a
          href="https://photos.app.goo.gl/6GShnnFGP7fD9TcK7"
          target="_blank"
          rel="noopener noreferrer"
          className="family-image-container inline"
        >
          <img
            className="family-image"
            src="rfpic_small.jpg"
            alt="rishi's family"
          ></img>{" "}
          <i className="fa fa-external-link fa-2x family-image-link"></i>
        </a>
      </div>
      Click on family pictures for more photos!
    </div>
  );
}

function Knees() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );
  return (
    <div>
      <h2>Knee activities</h2>
      <p>
        As you are reading the play, you may across some "Knee"s. Each Knee has
        a corresponding activity, listed below.{" "}
      </p>

      <Tablet target="/bespoke" name="Bespoke" number="I" />
      <Tablet target="/datamatch" name="Datamatch" number="II" />
      <Tablet target="/languages" name="Languages" number="III" />
      <Tablet target="/family" name="Family" number="IV" />
    </div>
  );
}

function Photos() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );
  return (
    <div>
      <h2>Photos</h2>
      <p>
        Please add your photos to this group album. Photos of yourself, photos
        of your location, photos of your sentiment card, or anything else!
      </p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="blue-link"
          href="https://photos.app.goo.gl/hqMe1ZrXMXBGeLhB9"
        >
          Link to album
        </a>
      </p>
      <p>(Feel free to share directly with us instead if you prefer!)</p>
    </div>
  );
}

function Hello() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );
  return (
    <div>
      <h1 className="center">
        Tharu and Rishi
        <br />
        get married: a website
      </h1>
      <p className="tiny center">September 27, 2020</p>
      <p className="pt1">
        We hope you have been enjoying reading the play. <br />
        Come see us say hello to you!
      </p>

      <div className="qblock">
        <a
          href="https://www.facebook.com/permalink.php?story_fbid=101712991702856&id=101296738411148"
          target="_blank"
          rel="noopener noreferrer"
          className="blue-link"
        >
          Facebook Livestream
        </a>
      </div>
    </div>
  );
}

function FourOhFour() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );
  return (
    <div>
      <h2>Not Found</h2>
    </div>
  );
}

function Tablet(props: { target: string; name: string; number: string }) {
  return (
    <div className="tablet-outer">
      <Link to={props.target} onClick={() => window.scrollTo(0, 0)}>
        <div className="tablet-inner">
          <div className="tablet-act">KNEE</div>
          <div className="tablet-number">{props.number}</div>
          <div className="tablet-name">{props.name}</div>
        </div>
      </Link>
    </div>
  );
}

export default App;
