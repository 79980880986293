import React, { useState, useEffect } from "react";
import interact from "interactjs";
import classNames from "classnames";

function Family() {
  useEffect(
    () => (window as any).ga("send", "pageview", window.location.pathname),
    []
  );

  // const [isYoung, setIsYoung] = useState(true);
  const [selectedAge, setSelectedAge] = useState("0");
  const isYoung = selectedAge === "0" || selectedAge === "1";
  const [showAnswers, setShowAnswers] = useState(false);

  return (
    <div>
      <h2>Family Match</h2>
      <div>
        Welcome to Family Match, the quiz about Rishi and Tharu's family members
        and what they have in common! To begin, please enter your age:
      </div>
      <div className="center">
        <div className="age-select-container">
          <div className="age-select">
            <select
              name="age"
              value={selectedAge}
              onChange={(e) => {
                setSelectedAge(e.target.value);
              }}
            >
              <option value="0">0</option>
              <option value="1">Under 40</option>
              <option value="2">40</option>
              <option value="3">Over 40</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        Next, drag and drop the photos below to the box of the person they
        belong to.
      </div>

      <div className="dragf-boundary">
        <div className="whole-family-container">
          <div className="whole-family">
            <FamilyBox name={isYoung ? "Nirmala Aunty" : "Nirmala"} />
            <FamilyBox name={isYoung ? "Ravi Uncle" : "Ravishankar"} /> <br />
            <FamilyBox name="Vaan" />
            <FamilyBox name="Tharu" />
          </div>
          <div className="whole-family">
            <FamilyBox name={isYoung ? "Manju Aunty" : "Manju"} />
            <FamilyBox name={isYoung ? "Vijay Uncle" : "Vijay"} /> <br />
            <FamilyBox name="Rishi" />
            <FamilyBox name="Seema" />
          </div>
        </div>

        <div className="family-photos">
          <img
            src="small/mf1_old2.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
          <img
            src="small/mf2_old2.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
          <img
            src="small/mf3_old2.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
          <img
            src="small/mf4_old1.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
          <img
            src="small/mf5_old1.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
          <img
            src="small/mf6_old3.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
          <img
            src="small/mf7_old1.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
          <img
            src="small/mf8_old2.jpg"
            alt="mystery family member"
            className="draggablef droppablef"
          ></img>
        </div>
      </div>
      <div className="family-bottom">
        You can check your answers by{" "}
        <div
          className="blue-link inline pointer"
          onClick={() => setShowAnswers(!showAnswers)}
        >
          clicking here
        </div>{" "}
        (make sure to fill out all eight before clicking the link!)
        {showAnswers && (
          <img
            src="family-answers.jpg"
            className="family-answers"
            alt="answer key"
          ></img>
        )}
      </div>
      <br />
      <a className="pt20 blue-link" href="..">
        Main page
      </a>
    </div>
  );
}

function FamilyBox(props: { name: string }) {
  return (
    <div className="family-box-outer">
      <div className="family-box-inner dropzonef"></div>
      <div className="family-box-subtitle">{props.name}</div>
    </div>
  );
}

// target elements with the "draggable" class
interact(".draggablef").draggable({
  // enable inertial throwing
  inertia: true,
  // keep the element within the area of its parent
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: ".dragf-boundary",
      endOnly: true,
    }),

    //  interact.modifiers.snap({
    //    targets: [interact.createSnapGrid({ x: 50, y: 50 })],
    //  }),
  ],

  listeners: {
    // call this function on every dragmove event
    move: dragMoveListen_,

    // call this function on every dragend event
    end(event) {},
  },
});

function dragMoveListen_(event: any) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

  // translate the element
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + y + "px)";

  // update the position attributes
  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);
}

// enable draggables to be dropped into this
interact(".dropzonef").dropzone({
  // only accept elements matching this CSS selector
  accept: ".droppablef",
  // Require a 75% element overlap for a drop to be possible
  overlap: 0.25,

  // listen for drop related events:

  ondropactivate: function (event) {
    // add active dropzone feedback
    event.target.classList.add("drop-active");
  },
  ondragenter: function (event) {
    var draggableElement = event.relatedTarget;
    var dropzoneElement = event.target;

    // feedback the possibility of a drop
    dropzoneElement.classList.add("drop-target");
    draggableElement.classList.add("can-drop");
  },
  ondragleave: function (event) {
    // remove the drop feedback style
    event.target.classList.remove("drop-target");
    event.relatedTarget.classList.remove("can-drop");
    // event.relatedTarget.textContent = "Dragged out";
  },
  ondrop: function (event) {
    /* setIdlis(idlis + 1);
     * if (idlis > 1 && step === 1) {
     *   setStep(2);
     * }*/
    // event.relatedTarget.textContent = "Dropped";
  },
  ondropdeactivate: function (event) {
    // remove active dropzone feedback
    event.target.classList.remove("drop-active");
    event.target.classList.remove("drop-target");
  },
});

export default Family;
